import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";

import styles from "./styles.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  variant?: "outline" | "primary" | "tertiary";
  size?: "md" | "sm";
  mode?: "light" | "dark";
  RightElement?: React.ComponentType<React.HTMLProps<HTMLElement>>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { variant, className, size, mode, children, RightElement, asChild, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        data-size={size}
        data-variant={variant}
        data-mode={mode}
        className={`${styles.button} ${className ? className : ""}`}
        ref={ref}
        {...props}
      >
        <Slottable>{children}</Slottable>
        {RightElement ? (
          <RightElement className={styles["right-element"]} />
        ) : null}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export default Button;
export type { ButtonProps };
