"use client";

import React, { useCallback, useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseIcon, UploadIcon } from "@/components/Icon";
import IconButton from "@/components/IconButton";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormProvider,
} from "@/components/Form";
import Typography from "@/components/Typography";
import styles from "./styles.module.css";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import { useModal } from "@/hooks/useModal";
import validator from "validator";
import submitForm from "@/utils/submitFormMail";

const formSchema = z.object({
  name: z.string().min(0, {
    message: "Необходимо заполнить поле",
  }),
  phone: z
    .string()
    .min(1, {
      message: "Необходимо заполнить поле",
    })
    .refine(
      (val) =>
        validator.isMobilePhone(
          val
            .slice(1)
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(" ", "")
            .replaceAll("-", ""),
          "ru-RU"
        ),
      {
        message: "Поле Введено некоректно",
      }
    ),
  description: z.string().min(1, {
    message: "Необходимо заполнить поле",
  }),
  // files: z.any().refine((val) => val > 0, {
  //   message: "Необходимо заполнить поле",
  // }),
  files: z.any(),
  acceptTerms: z.boolean().refine((val) => val === true, {
    message: "Необходимо согласиться",
  }),
});

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ContactDialog: React.FC<Props> = ({
  open,
  onClose = () => {},
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phone: "",
      description: "",
      files: 0,
      acceptTerms: false,
    },
  });
  const registerWithMask = useHookFormMask(form.register);
  const { close, show, visible } = useModal({ defaultVisible: false });
  const [files, setFiles] = useState<File[]>([]);

  const handleClose = useCallback(() => {
    close();
    onClose();
    setFiles([]);

    form.reset({
      name: "",
      phone: "",
      description: "",
      files: 0,
      acceptTerms: false,
    });
  }, [close, onClose, form]);

  const onSubmit = useCallback(
    (values: z.infer<typeof formSchema>) => {
      const formData = new FormData();
      formData.set("name", values.name);
      formData.set("phone", values.phone);
      formData.set("description", values.description);
      files.forEach((file, idx) => {
        formData.set(`file_${idx}`, file);
      });
      submitForm(formData);

      show();
    },
    [files, show]
  );

  const handleFileUpload = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const fileList = (e.target as HTMLInputElement).files;
      if (!fileList) return;

      let files: { file: File; fileSize: number }[] = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const fileSize = file.size / 1000000;
        files.push({ file, fileSize });
      }
      files = files.filter(({ fileSize }) => fileSize <= 10);
      files = files.slice(0, 3);
      setFiles(files.map(({ file }) => file));

      if (files.length == 0 && fileList.length > 0) {
        form.setError("files", {
          type: "maxLength",
          message: "Файл не должен превышать 10мб",
        });
      } else {
        form.resetField("files");
      }
    },
    [form]
  );

  const handleRemoveFile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const fileId = Number(e.currentTarget.getAttribute("data-id"));
      setFiles((files) => {
        const newFiles = [...files];
        newFiles.splice(fileId, 1);
        return newFiles;
      });
    },
    []
  );

  useEffect(() => {
    form.setValue("files", files.length);
  }, [files, form]);

  return (
    <>
      <Dialog.Root open={open}>
        <Dialog.Portal>
          <Dialog.Overlay className={styles.DialogOverlay} />
          {visible ? (
            <Dialog.Content
              className={`${styles.DialogContent} ${styles.DialogContentSuccess}`}
              onEscapeKeyDown={handleClose}
              onPointerDownOutside={handleClose}
              onInteractOutside={handleClose}
            >
              <div className={styles.DialogSuccessContentContainer}>
                <Dialog.Title asChild>
                  <Typography variant="h2" className={styles.DialogTitle}>
                    Успешно!
                  </Typography>
                </Dialog.Title>
                <Dialog.Description asChild>
                  <Typography
                    variant="p1"
                    className={`${styles.DialogDescription} ${styles.DialogSuccessDescription}`}
                  >
                    <>
                      Спасибо за вашу заявку.
                      <br />
                      Мы свяжемся с вами в ближайшее время!
                    </>
                  </Typography>
                </Dialog.Description>
                <Button
                  variant="primary"
                  className={`${styles.SubmitBtn} ${styles.SubmitBtnSuccess}`}
                  onClick={handleClose}
                >
                  Закрыть
                </Button>
              </div>
              <Dialog.Close asChild>
                <IconButton
                  onClick={handleClose}
                  className={`${styles.CloseBtn}`}
                  aria-label="Close"
                  Icon={CloseIcon}
                  variant="circle"
                ></IconButton>
              </Dialog.Close>
            </Dialog.Content>
          ) : (
            <Dialog.Content
              className={styles.DialogContent}
              onEscapeKeyDown={handleClose}
              onPointerDownOutside={handleClose}
              onInteractOutside={handleClose}
            >
              <Dialog.Title asChild>
                <Typography variant="h2" className={styles.DialogTitle}>
                  Обсудить проект
                </Typography>
              </Dialog.Title>
              <Dialog.Description asChild>
                <Typography variant="p3" className={styles.DialogDescription}>
                  Заполните форму и мы свяжемся с вами в ближайшее время
                </Typography>
              </Dialog.Description>
              <FormProvider {...form}>
                <Form
                  className={styles.form}
                  onSubmit={form.handleSubmit(onSubmit)}
                >
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className={styles.form__item}>
                        <FormLabel className={styles.label} htmlFor="name">
                          Имя
                        </FormLabel>
                        <FormControl>
                          <input
                            {...field}
                            placeholder="Имя"
                            name="name"
                            id="name"
                            className={styles.input}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem className={styles.form__item}>
                        <FormLabel className={styles.label} htmlFor="phone">
                          Телефон
                        </FormLabel>
                        <FormControl>
                          <>
                            <input
                              {...registerWithMask("phone", [
                                "+7 (999) 999-99-99",
                              ])}
                              placeholder="Телефон *"
                              name="phone"
                              id="phone"
                              type="tel"
                              className={`${styles.input} ${
                                form.formState.errors.phone?.message
                                  ? styles.error
                                  : ""
                              }`}
                            />
                            <span className={styles.errorMessage}>
                              {form.formState.errors.phone?.message}
                            </span>
                          </>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem className={styles.form__item}>
                        <FormLabel
                          className={styles.label}
                          htmlFor="description"
                        >
                          Опишите свой проект
                        </FormLabel>
                        <FormControl>
                          <>
                            <textarea
                              {...field}
                              placeholder="Опишите свой проект *"
                              name="description"
                              id="description"
                              className={`${styles.textarea} ${
                                form.formState.errors.description?.message
                                  ? styles.error
                                  : ""
                              }`}
                            />
                            <span className={styles.errorMessage}>
                              {form.formState.errors.description?.message}
                            </span>
                          </>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="files"
                    render={({ field }) => {
                      return (
                        <FormItem className={styles.form__item}>
                          <FormControl>
                            <label
                              className={`${styles.files_container} ${
                                form.formState.errors.files?.message
                                  ? styles.error
                                  : ""
                              }`}
                            >
                              <span className={styles.files_text}></span>
                              <input
                                onChange={handleFileUpload}
                                placeholder=""
                                name="files"
                                id="files"
                                type="file"
                                accept=".doc,.docx,.pdf"
                                multiple
                                className={`${styles.files} ${
                                  form.formState.errors.files?.message
                                    ? styles.error
                                    : ""
                                }`}
                              />
                              Прикрепить файл
                              <UploadIcon className={styles.files_btn} />
                            </label>
                          </FormControl>
                          {files.length > 0 && (
                            <div className={styles.fileList}>
                              {files.map(({ name }, idx) => (
                                <button
                                  key={name}
                                  onClick={handleRemoveFile}
                                  data-id={idx}
                                  type="button"
                                >
                                  {name} <CloseIcon />
                                </button>
                              ))}
                            </div>
                          )}
                          {files.length <= 0 &&
                            (form.formState.errors.files?.message ? (
                              <span className={styles.errorMessage}>
                                {/* @ts-ignore */}
                                {form.formState.errors.files?.message}
                              </span>
                            ) : (
                              <span className={styles.files_description}>
                                Вы можете загрузить не более 3-х файлов pdf, doc
                                до 10 МБ
                              </span>
                            ))}
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="acceptTerms"
                    render={({ field }) => (
                      <FormItem className={styles.form__item}>
                        <FormControl>
                          <label
                            htmlFor="acceptTerms"
                            className={`${styles.terms} ${
                              form.formState.errors.acceptTerms?.message
                                ? styles.error
                                : ""
                            }`}
                          >
                            <Checkbox
                              id="acceptTerms"
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                            Я согласен на обработку персональных данных
                          </label>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <Dialog.Close asChild>
                    <Button
                      variant="primary"
                      className={styles.SubmitBtn}
                      type="submit"
                    >
                      Отправить заявку
                    </Button>
                  </Dialog.Close>
                </Form>
              </FormProvider>
              <Dialog.Close asChild>
                <IconButton
                  onClick={handleClose}
                  className={styles.CloseBtn}
                  aria-label="Close"
                  Icon={CloseIcon}
                  variant="circle"
                ></IconButton>
              </Dialog.Close>
            </Dialog.Content>
          )}
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
